const ArrowRound = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5623 13.2861L25.8049 17.5287C26.0653 17.7891 26.0653 18.2112 25.8049 18.4715L21.5623 22.7142C21.3019 22.9745 20.8798 22.9745 20.6195 22.7142C20.3591 22.4538 20.3591 22.0317 20.6195 21.7714L23.724 18.6668L9.3335 18.6668L9.3335 17.3335L23.724 17.3335L20.6195 14.2289C20.3591 13.9685 20.3591 13.5464 20.6195 13.2861C20.8798 13.0257 21.3019 13.0257 21.5623 13.2861Z"
        fill="#B3C4FF"
      />
      <rect
        x="34.6667"
        y="1.33333"
        width="33.3333"
        height="33.3333"
        rx="16.6667"
        transform="rotate(90 34.6667 1.33333)"
        stroke="#B3C4FF"
        strokeWidth="1.33333"
      />
    </svg>
  );
};

export default ArrowRound;
