import styles from './FeatureCard.module.scss';

import ArrowRound from '#components/ui/Icons/ArrowRound';
import LeakIcon from '#images/common/icon-leak.svg';
import LocationByLinkIcon from '#images/common/icon-location-by-link.svg';
import LocationByNumberIcon from '#images/common/icon-location-by-number.svg';
import MessageIcon from '#images/common/icon-message.svg';
import NicknameLookupIcon from '#images/common/icon-nickname-lookup.svg';
import SearchIcon from '#images/common/icon-search.svg';
import VoiceMessageIcon from '#images/common/icon-voice-message.svg';
import { prepareUrl } from '#utils/prepareUrl';
import { getFeatureNameStorageParam } from '#utils/getFeatureNameStorageParam';
import { clearFeaturesStorage } from '#utils/clearFeaturesStorage';

type Props = {
  feature: {
    title: string;
    description: string;
    type: string;
  };
  lang: string;
};

const FeatureCard = ({ feature, lang }: Props) => {
  const preparedLink = prepareUrl('/email', lang);
  const getIconType = (type: string) => {
    switch (type) {
      case 'locationByLink':
        return LocationByLinkIcon;
      case 'locationByNumber':
        return LocationByNumberIcon;
      case 'reversePhoneLookup':
        return SearchIcon;
      case 'reverseNicknameLookup':
        return NicknameLookupIcon;
      case 'leakChecker':
        return LeakIcon;
      case 'sendVoiceMsg':
        return VoiceMessageIcon;
      case 'messageToLostPhone':
        return MessageIcon;
      default:
        return null;
    }
  };

  const handleClick = () => {
    const currentFeature = getFeatureNameStorageParam(window.location.pathname);
    if (currentFeature) {
      clearFeaturesStorage();
      sessionStorage.setItem(currentFeature, 'true');
    }
    if (typeof window !== 'undefined') window.location.href = preparedLink;
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardWrapper} onClick={handleClick}>
        <div className={styles.cardTop}>
          <img
            src={getIconType(feature.type)?.src}
            alt={`${feature.type} icon`}
            loading="lazy"
            className={styles.cardTitleIcon}
          />
          <h3 className={styles.cardTitle}>{feature.title}</h3>
        </div>
        <div className={styles.cardContent}>{feature.description}</div>
        <div className={styles.cardFooter}>
          <img
            src={getIconType(feature.type)?.src}
            alt={`${feature.type} icon`}
            loading="lazy"
            className={styles.cardBackgroundIcon}
          />
          <button type="button" onClick={handleClick}>
            <ArrowRound />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
